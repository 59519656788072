<template>
  <div id="customDashboardPage">
    <!-- Loader -->
    <div class="pageLoaderDiv" v-if="fetchingData" v-loading="true"></div>

    <template v-else>
      <el-row :gutter="20" v-if="dashboardCardList && dashboardCardList.length > 0" class="customDashboard">
        <draggable
          v-model="dashboardCardList"
          group="people"
          @start="dragCard = true"
          @end="
            dragCard = false;
            onChartOrderChange();
          "
          v-bind="dragOptions"
          handle=".draggingBar"
        >
          <transition-group type="transition" :name="!dragCard ? 'flip-list' : null">
            <el-col :span="getCardWidthForGivenCard(card.card_type)" class="cardItem" v-for="(card, index) in dashboardCardList" :key="index">
              <div class="overflow-hidden border border-gray-100 sm:rounded-lg">
                <!-- Card HEader -->
                <div class="bg-gray-50 border-b border-b-gray-200 px-3 py-2 leading-7 text-md headerOfCard">
                  <!-- // Edit input  -->
                  <span v-if="!card.editMode">
                    {{ card.name }}
                    <!-- <i class="el-icon-edit" size='mini' @click="editDashboardCard(card)"></i>
                      <i class="el-icon-delete" size='mini' @click="deleteDashboardCard(card, index)"></i> -->
                  </span>
                  <div class="cancelBtnOfCard" v-else>
                    <el-input placeholder="Enter dashboard name" v-model="card.name" @keyup.enter.native="updateDashboardCardName(card)"></el-input>
                    <el-button size="mini" class="cancelBtn" type="primary" plain @click="cancelDashboardCardEditMode(card)"><i class="el-icon-close"></i></el-button>
                  </div>

                  <!-- // Data Duration  -->
                  <span class="durationInfo" v-if="card.graph && !card.editMode">
                    <span style="text-transform: capitalize" v-if="card.card_type == 'dynamic_timeline_graph'"> {{ getDurationGroupDisplayString(card.graph.durationGroup) }}, </span>
                    <span v-if="card.graph.durationType == 'latest'">
                      Last
                      {{ card.graph.durationText ? card.graph.durationText : getDurationAsDay(parseInt(card.graph.duration)) }}
                    </span>
                    <span v-else> </span>
                  </span>
                  <span class="durationInfo" v-else-if="card.data">
                    <span style="text-transform: capitalize" v-if="card.card_type == 'dynamic_timeline_graph'"> {{ getDurationGroupDisplayString(card.data.durationGroup) }}, </span>
                    <span v-if="card.graph.durationType == 'latest'">
                      Last
                      {{ card.graph.durationText ? card.graph.durationText : getDurationAsDay(parseInt(card.data.duration)) }}
                    </span>
                    <span v-else> </span>
                  </span>

                  <!-- Right-side re-order icon -->
                  <div class="rightSide">
                    <!-- Explore Icon For Cohorts -->
                    <!-- <template v-if="card.card_type.startsWith('cohort')">
                      <a :href="`/analytics/retention?retentionQuery=${card.paramsHashed}`" target="_blank" class="ml-2">
                        <el-button class="mr-4 py-1 px-2" type="primary" size="mini" plain>
                          Explore Cohort
                          <i class="el-icon-top-right text-primary-600 hover:text-white"></i>
                        </el-button>
                      </a>
                    </template> -->

                    <el-tooltip class="item" effect="dark" content="Adjust card" placement="bottom">
                      <i class="fa fa-arrows draggingBar" v-if="!card.editMode" aria-hidden="true"></i>
                    </el-tooltip>
                    <el-dropdown trigger="click" v-if="!card.editMode" @command="handleCommand">
                      <span class="sideMenuIconOfCard">
                        <i class="el-icon-setting" aria-hidden="true"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item :command="{ action: 'editOfCard', card: card }" icon="el-icon-edit">Edit</el-dropdown-item>
                        <el-dropdown-item
                          :command="{
                            action: 'deleteOfCard',
                            card: card,
                            index: index
                          }"
                          icon="el-icon-delete"
                          >Delete</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>

                <!-- Card Content -->
                <div class="bg-white">
                  <!-- Loader -->
                  <div class="loaderContainer" v-if="card.fetchingData">
                    <beat-loader class="loader" :loading="true" color="rgb(220, 223, 230)" size="10px"></beat-loader>
                  </div>

                  <!-- // Dynamic timeline graph -->
                  <div class="pt-4 pb-2 position-relative" v-if="card.card_type == 'dynamic_timeline_graph' && !card.fetchingData">
                    <div class="exportButton bg-gray-100 border-b border-l px-2 cursor-pointer" @click="exportItemCSV(index)">
                      <el-tooltip class="item" effect="dark" content="Export Report" placement="left">
                        <i class="fa fa-download text-xs text-gray-600" aria-hidden="true"></i>
                      </el-tooltip>
                    </div>
                    <DynamicTimelineGraphComponent :ref="'component' + index" v-bind:duration="card.graph.duration" v-bind:durationGroup="card.graph.durationGroup" v-bind:graph="card.graph"></DynamicTimelineGraphComponent>
                  </div>

                  <!-- // Dynamic bar graph -->
                  <div class="pt-4 pb-2" v-if="card.card_type == 'dynamic_bar_graph' && !card.fetchingData">
                    <DynamicBarGraphComponent :ref="'component' + index" v-bind:duration="card.graph.duration" v-bind:graph="card.graph"></DynamicBarGraphComponent>
                  </div>

                  <!-- // Dynamic area graph -->
                  <div class="pt-4 pb-2" v-if="card.card_type == 'dynamic_area_graph' && !card.fetchingData">
                    <DynamicTimelineGraphComponent :ref="'component' + index" v-bind:duration="card.graph.duration" v-bind:graph="card.graph"></DynamicTimelineGraphComponent>
                  </div>

                  <!-- // Dynamic horizontal bar graph-->
                  <div class="pt-4 pb-2" v-if="card.card_type == 'dynamic_horizontal_bar_graph' && !card.fetchingData">
                    <DynamicHorizontalBarGraphComponent v-bind:duration="card.graph.duration" v-bind:graph="card.graph"></DynamicHorizontalBarGraphComponent>
                  </div>

                  <!-- // Four Column Number -->
                  <div class="pt-4 pb-2" v-if="card.card_type == 'device_distribution' && !card.fetchingData">
                    <FourColumnNumberComponent v-bind:data="card.graph"></FourColumnNumberComponent>
                  </div>

                  <!-- // Two Column Number -->
                  <div class="pt-4 pb-2" v-if="card.card_type == 'uninstall_install_platform_distribution' && !card.fetchingData">
                    <TwoColumnNumberComponent v-bind:data="card.graph"></TwoColumnNumberComponent>
                  </div>

                  <!-- // one Column Number -->
                  <div class="pt-2 pb-2" v-if="(card.card_type == 'total_events' || card.card_type == 'event_users' || card.card_type == 'unique_sessions' || card.card_type == 'total_sessions') && !card.fetchingData">
                    <OneColumnNumberComponent v-bind:data="card.graph"></OneColumnNumberComponent>
                  </div>

                  <!-- // Event Property Distribution Graph -->
                  <div class="pt-4 pb-2" v-if="isCardTypeIsEventDetailsBarChart(card.card_type) && !card.fetchingData">
                    <BarGraph v-bind:duration="card.graph.duration" v-bind:durationGroup="card.graph.durationGroup" v-bind:graph="card.graph"></BarGraph>
                  </div>

                  <!-- // Funnel Conversion Graph -->
                  <div class="pt-4 pb-2" v-if="card.card_type == 'funnel_conversion_graph' && !card.fetchingData">
                    <FunnelGraph v-bind:duration="card.graph.duration" v-bind:durationGroup="card.graph.durationGroup" v-bind:graph="card.graph"></FunnelGraph>
                  </div>

                  <!-- // Cohort Table Conversion Graph -->
                  <div class="pt-4 pb-2" v-if="card.card_type == 'cohort_graph' && !card.fetchingData">
                    <CohortGraph :params="card.params" :data="card.data"></CohortGraph>
                  </div>

                  <!-- // Cohort Table Conversion Graph -->
                  <div v-if="card.card_type == 'cohort_table' && !card.fetchingData">
                    <CohortTable :params="card.params" :data="card.data"></CohortTable>
                  </div>
                </div>
              </div>
            </el-col>
          </transition-group>
        </draggable>
      </el-row>

      <!-- IF dashboard list is empty. -->
      <div class="noDashboardFound" v-else>
        <img src="https://static.growlytics.in/dashboard-assets/assets/img/segments_icon.png" style="width: 100px" />
        <br />
        Dashboard Not Found.
      </div>
    </template>
  </div>
</template>

<style lang="scss" src="./customDashboard.scss"></style>

<script>
import CustomDashboardComponent from './customDashboardComponent';
export default CustomDashboardComponent;
</script>
