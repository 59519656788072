<template>
  <div class="twoColumnDashboardComponent">
    <el-row>
      <el-col :span="12">
        <div class="numbers">{{ data[0].value ? data[0].value : 0 }}</div>
        <div class="title">{{ data[0].title }}</div>
      </el-col>
      <el-col :span="12">
        <div class="numbers">{{ data[1].value ? data[1].value : 0 }}</div>
        <div class="title">{{ data[1].title }}</div>
      </el-col>
    </el-row>
  </div>
</template>

<style lang="scss" src="./twoColumnNumber.scss"></style>

<script>
import TwoColumnNumberComponent from './twoColumnNumberComponent';
export default TwoColumnNumberComponent;
</script>
