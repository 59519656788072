import dashboardModule from '../../../services/dashboard';
import DynamicTimelineGraphComponent from '@/components/graphs/dynamicTimelineGraph/dynamicTimelineGraph';
import DynamicBarGraphComponent from '@/components/graphs/dynamicBarGraph/dynamicBarGraph';
import DynamicHorizontalBarGraphComponent from '@/components/graphs/horizontalBarGraph/horizontalBarGraph';
import OneColumnNumberComponent from '@/components/graphs/oneColumnNumber/oneColumnNumber';
import BarGraph from '@/components/graphs/barGraph/barGraph';
import FunnelGraph from '@/components/graphs/funnelGraph/funnelGraph';
import FourColumnNumberComponent from '@/components/graphs/fourColumnNumber/fourColumnNumber';
import TwoColumnNumberComponent from '@/components/graphs/twoColumnNumber/twoColumnNumber';
import draggable from 'vuedraggable';
import BeatLoader from '@/components/loaders/BeatLoader.vue';
import companyModule from '@/services/company';
import CohortGraph from '@/components/graphs/cohortGraph/cohortGraph';
import CohortTable from '@/components/graphs/cohortGraph/cohortTable';

// const _ = require('lodash');

const moment = require('moment');
export default {
  data() {
    return {
      fetchingData: true,
      dashboardCardList: [],
      drag: false,
      dragCard: false,
      durationList: {
        1440: 'Last 24 Hours',
        2880: 'Last 2 Days',
        10080: 'Last 7 Days',
        21600: 'Last 15 Days',
        43200: 'Last 30 Days'
      },

      groupByDurationList: [
        {
          key: 'Hourly',
          value: 'hour'
        },
        {
          key: 'Daily',
          value: 'day'
        },
        {
          key: 'Weekly',
          value: 'week'
        },
        {
          key: 'Monthly',
          value: 'month'
        }
      ],
      eventDetailsBarChartList: ['event_property_distribution_bar_graph', 'sessions_per_day_graph', 'hourly_avg_event_graph', 'city_distribution_graph', 'traffic_source_distribution_graph', 'top_pages_distribution_graph', 'region_distribution_graph', 'country_distribution_graph', 'device_distribution_graph', 'operating_system_distribution_graph', 'browser_distribution_graph'],

      // loader variables
      deletingCard: false,
      deletingDashboard: false,

      // Dashboard util methods
      dashboardNameMap: {},
      dashboardCardNameMap: {}
    };
  },
  components: {
    BeatLoader,
    draggable,
    DynamicTimelineGraphComponent,
    DynamicBarGraphComponent,
    DynamicHorizontalBarGraphComponent,
    FourColumnNumberComponent,
    OneColumnNumberComponent,
    BarGraph,
    FunnelGraph,
    TwoColumnNumberComponent,
    CohortGraph,
    CohortTable
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      };
    },
    dragDashboardOptions() {
      return {
        animation: 100,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      };
    }
  },
  watch: {
    '$route.params.dashboardId': function () {
      this.handleMount();
      this.$root.$emit('onDashboardListItemChange', this.$route.params.dashboardId);
    }
  },
  methods: {
    changeDurationGroup(card) {
      card.graph.durationGroupList = this.getGroupByDurationList(card.graph.duration);
      card.graph.durationGroup = card.graph.durationGroupList[0].value;
    },

    getDurationGroupDisplayString(durationGroup) {
      if (durationGroup == 'day') {
        return 'daily';
      } else {
        return durationGroup + 'ly';
      }
    },

    getDurationAsDay(minutes) {
      if (minutes < 60 * 24) {
        return moment.duration(minutes, 'minutes').asDays() + ' hours';
      } else if (minutes < 60 * 24 * 30 * 3) {
        return moment.duration(minutes, 'minutes').asDays() + ' days';
      } else {
        return moment.duration(minutes, 'minutes').asMonths() + ' months';
      }
    },

    //#region -------------- end - chart reordering-------------

    exportItemCSV(index) {
      this.$refs['component' + index][0].exportCSV();
    },

    getCardWidthForGivenCard(type) {
      if (type == 'total_events' || type == 'event_users' || type == 'total_sessions' || type == 'unique_sessions' || type == 'traffic_source_distribution_graph' || type == 'top_pages_distribution_graph' || type == 'device_distribution_graph' || type == 'browser_distribution_graph' || type == 'country_distribution_graph' || type == 'city_distribution_graph') {
        return 12;
      } else {
        return 24;
      }
    },

    onChartOrderChange() {
      let chartOrder = [];
      for (var i = 0; i < this.dashboardCardList.length; i++) {
        let cardId = this.dashboardCardList[i].id;
        chartOrder.push(cardId);
      }

      let params = {
        dashboard: this.dashboardId,
        order: chartOrder
      };

      dashboardModule
        .saveDashboardCardOrder(params, this)
        .then(() => {
          //saved successull
        })
        .catch((error) => {
          this.reportError(error);
          this.errorToast('Something went wrong. Failed to save charts order.');
        });
    },

    //#endregion -------------- end - chart reordering-------------

    isCardTypeIsEventDetailsBarChart(type) {
      if (this.eventDetailsBarChartList.indexOf(type) == -1) {
        return false;
      }
      return true;
    },

    async applyDashboard(dashboardId) {
      console.log('apply dashboard resqus', dashboardId);
      this.dashboardCardList = null;
      try {
        let result = await dashboardModule.fetchDashboardCardList(dashboardId, this);
        this.dashboardCardList = result.data.data;
        this.renderDashboardCards();
      } catch (err) {
        this.reportError(err);
      }
    },

    renderDashboardCards() {
      this.dashboardCardList.forEach((card) => {
        this.dashboardCardNameMap[card.id] = card.name;
        card.fetchingData = true;
        card.editMode = false;
        let params = {
          dashboardCardId: card.id
        };

        dashboardModule
          .fetchDashboardCardData(params, this)
          .then((result) => {
            if (result.data.success) {
              card.graph = result.data.data.graph;
              card.data = result.data.data.data;
              card.params = result.data.data.params;
              card.paramsHashed = result.data.data.paramsHashed;
              card.fetchingData = false;
              this.$forceUpdate();
            }
          })
          .catch(() => {
            card.fetchingData = false;
          });
      });
    },

    /*********************************** Start: Dashboard util methods  *********************************/

    editDashboardCard(card) {
      card.editMode = true;
      this.$forceUpdate();
    },

    updateDashboardCardName(card) {
      if (!card.name) {
        this.errorToast('Card name can not be empty.');
        return;
      }
      let params = {
        name: card.name,
        cardId: card.id
      };
      dashboardModule
        .updateDashboardCardById(params, this)
        .then(() => {
          this.dashboardCardNameMap[card.id] = card.name;
          card.editMode = false;
          this.$forceUpdate();
        })
        .catch((e) => {
          this.reportError(e);
          card.editMode = false;
          this.$forceUpdate();
        });
    },

    deleteDashboardCard(card, index) {
      this.$swal({
        title: 'Delete card?',
        text: "You won't be able to undo this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(() => {
        setTimeout(() => {
          this.deletingCard = true;
          dashboardModule
            .deleteDashboardCardById(card.id, this)
            .then((result) => {
              if (result.data.success) {
                this.dashboardCardList.splice(index, 1);
                this.successToast(`Card deleted successfully.`);
              }
              this.deletingCard = false;
            })
            .catch((e) => {
              this.reportError(e);
              this.errorToast(`Something went wrong.`);
              this.deletingCard = false;
            });
        }, 50);
      });
    },

    cancelDashboardCardEditMode(card) {
      card.name = this.dashboardCardNameMap[card.id];
      card.editMode = false;
      this.$forceUpdate();
    },

    handleCommand(command) {
      if (command.action == 'edit') {
        this.editDashboard(command.dashboard);
      }
      if (command.action == 'delete') {
        this.deleteDashboard(command.dashboard, command.index);
      }
      if (command.action == 'editOfCard') {
        this.editDashboardCard(command.card);
      }
      if (command.action == 'deleteOfCard') {
        this.deleteDashboardCard(command.card, command.index);
      }
    },

    /*********************************** End: Dashboard util methods  *********************************/

    async checkForCompanySetup() {
      let result = await companyModule.getCompanySetupStatus(this);
      if (!result.data.done) {
        this.$router.replace('/setup');
      }
    },

    handleMount() {
      this.fetchingData = true;
      //Read dashbaord list.
      dashboardModule
        .fetchDashboardList(this)
        .then((result) => {
          let dashboardList = result.data.data;
          dashboardList.forEach((dashboard) => {
            this.dashboardNameMap[dashboard.id] = dashboard.name;
            dashboard.editMode = false;
          });

          let dashboardId = this.$route.params.dashboardId;
          if (dashboardId) {
            this.applyDashboard(dashboardId);
          } else {
            console.log('Load default shopify dashboard');
          }
          this.fetchingData = false;
        })
        .catch((err) => {
          this.reportError(err);
          this.fetchingData = false;
        });
    }
  },
  mounted() {
    this.handleMount();
    this.checkForCompanySetup();
  }
};
